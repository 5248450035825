export default {
  // https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/
  color: {
    white: '#FFFFFF',
    lightgray1: '#e5e5ea',
    lightgray2: '#d1d1d6',
    lightgray3: '#f2f2f7',
    gray1: '#C4C4C4',
    gray2: '#8e8e93',
    gray3: '#48484a',
    gray4: '#3a3a3c',
    gray5: '#2c2c2e',
    black1: '#000000',
    black2: '#141414',
    orange: '#F37021',
    blue: '#0a84ff',
    red: '#ff443a',
    green: '#30d158',
  },
  easingFn: {
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
    decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  baseNum: 8,
  layout() {
    return {
      margin1: this.baseNum * 16,
      margin2: this.baseNum * 8,
      margin3: this.baseNum * 4,
      margin4: this.baseNum * 2,
      margin5: this.baseNum,
      padding1: this.baseNum * 8,
      padding2: this.baseNum * 4,
      padding3: this.baseNum * 3,
      padding4: this.baseNum * 2,
      padding5: this.baseNum,
    };
  },
  border() {
    return {
      radius1: this.baseNum * 4,
      radius2: this.baseNum * 2,
      radius3: this.baseNum,
    };
  },
  ellipsis: `
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  dropShadow: {
    normal: '0px 3px 8px rgba(0, 0, 0, 0.15)',
    repressed: '0px 8px 12px rgba(0, 0, 0, 0.15)',
  },
  device: {
    mobile: 'only screen and (max-width: 768px)',
    tablet: 'only screen and (min-width: 768px) and (max-width: 1199px)',
    desktop: 'only screen and (min-width: 1200px)',
  },
  br: `br {
    content: "";
    display: block;
    margin: 0.75em 0;
  }`,
};
