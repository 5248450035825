import { Auth } from 'aws-amplify';

export default () => ({
  type: 'GET_USER',
  payload: (async () => {
    const userData = await Auth.currentAuthenticatedUser();

    try {
      return {
        name: userData.username,
        email: userData.attributes.email,
        groups: userData.signInUserSession.idToken.payload['cognito:groups'],
        editingCompany: JSON.parse(
          userData.attributes['custom:editingCompany'] || '[]'
        ),
      };
    } catch (e) {
      return {};
    }
  })(),
});
