import React from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';

const Container = styled.div`
  flex-shrink: 0;
  border-top: 1px solid ${$.color.lightgray1};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding4}px calc((100vw - 1200px) / 2);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 15px;
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 15px;
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion
`;

const Footer = () => (
  <Container>
    &copy;
    {` Copyright ${new Date().getFullYear()}. All rights reserved.`}
  </Container>
);

export default Footer;
