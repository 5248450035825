import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../styles/global';
import Utils from '../../utils';
import { closeModal } from '../../actions';
import DeleteIcon from '../../assets/icons/delete.svg';
import Button from '../Button';

const Background = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(44, 49, 59, 0.67);
  top: 0;
  left: 0;

  &.show {
    pointer-events: initial;
    opacity: 1;
    z-index: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: calc(400px - ${$.layout().padding3 * 2}px);
  margin-top: 30vh;
  margin-left: calc((100vw - 400px) / 2);
  background-color: ${$.color.white};
  border-radius: 10px;
  padding: ${$.layout().padding3}px;
  box-shadow: ${$.dropShadow.repressed};

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    max-width: calc(330px - ${$.layout().padding3 * 2}px);
    margin-left: calc((100vw - 330px) / 2);
    margin-top: 25vh;
  }
  // #endregion
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${$.layout().margin3}px;

  & > svg {
    width: 24px;
    height: 24px;
    fill: ${$.color.black2};
  }

  & > span {
    font-size: 17px;
    font-family: SF Pro Bold;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    & > svg:hover {
      fill: ${$.color.gray2};
      cursor: pointer;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    & > svg {
      width: 26px;
      height: 26px;
      fill: ${$.color.black2};
    }

    & > span {
      font-size: 18px;
    }
  }
  // #endregion
`;

const Message = styled.div`
  line-height: 1.3em;
  margin-bottom: ${$.layout().margin3}px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > * {
    &:not(:last-child) {
      margin-right: ${$.layout().margin5}px;
    }
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    & > * {
      &:not(:last-child) {
        margin-right: ${$.layout().margin4}px;
      }
    }
  }
  // #endregion
`;

const StyledButton = styled(Button)`
  ${({ color }) => `
  background-color: ${color};
  width: 80px;
  font-size: 15px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 18px;
    padding: 12px;
    width: 100%;
  }
  // #endregion
`}
`;

const Modal = () => {
  const containerRef = useRef(null);
  const data = useSelector(({ modalData }) => modalData);
  const [clearAndCloseModal] = Utils.useActions([closeModal]);

  Utils.useOutsideClick([containerRef], () => {
    if (containerRef.current.parentNode.className.indexOf('show') > -1) {
      clearAndCloseModal();
    }
  });

  return (
    <Background className={data.open ? 'show' : ''}>
      <Container ref={containerRef}>
        <Title>
          <span>{data.title}</span>
          <DeleteIcon
            onClick={() => {
              clearAndCloseModal();
            }}
          />
        </Title>
        <Message>{data.message}</Message>
        <Buttons>
          {data.options.map(({ text, color, callback }, index) => (
            <StyledButton
              key={`modal_button_${index}`}
              color={color}
              onClick={() => {
                callback();
                clearAndCloseModal();
              }}
            >
              {text}
            </StyledButton>
          ))}
        </Buttons>
      </Container>
    </Background>
  );
};

export default Modal;
