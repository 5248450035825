const initialState = {
  jobType: {},
  workExperience: {},
  jobCategory: {},
  openSideBarFilter: false,
  searchTerm: '',
};

export default (state = initialState, { payload, type } = {}) => {
  const newState = { ...state };

  switch (type) {
    case 'UPDATE_FILTER':
      if (payload.value === false) {
        delete newState[payload.type][payload.enum];
      } else {
        newState[payload.type] = {
          ...newState[payload.type],
          [payload.enum]: true,
        };
      }
      return newState;
    case 'OPEN_SIDEBAR_FILTER':
      newState.openSideBarFilter = payload;
      return newState;
    case 'SET_SEARCH_TERM':
      newState.searchTerm = payload;
      return newState;
    case 'RESET_FILTER':
      return {
        jobType: {},
        workExperience: {},
        jobCategory: {},
        openSideBarFilter: newState.openSideBarFilter,
        searchTerm: '',
      };
    default:
      return state;
  }
};
