const initialState = [];

export default (state = initialState, { payload, type } = {}) => {
  const newState = [...state];
  let found;

  switch (type) {
    case 'UPDATE_JOB':
      found = newState.filter(({ id }) => id === payload.id);

      if (found && found.length === 0) {
        newState.push(payload);
        return newState;
      }

      return newState.map(({ id, ...rest }) => {
        if (id === payload.id) {
          return { ...payload };
        }

        return { id, ...rest };
      });
    case 'CREATE_JOB':
      return [...newState, payload];
    case 'DELETE_JOB':
      return newState.filter(({ id }) => id !== payload);
    case 'UPDATE_JOBS':
      return [...payload];
    default:
      return state;
  }
};
