/*eslint-disable */

import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import Root from './src/components/Root';
import Layout from './src/components/Layout';
import './src/styles/reset.css';
import './src/styles/global.css';
import './src/styles/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Polyfill for smooth scrolling in mobile
 */
smoothscroll.polyfill();

const wrapRootElement = Root;

const wrapPageElement = ({ element: page }) => {
  return <Layout>{page}</Layout>;
};

export default {};

export { wrapRootElement, wrapPageElement };
