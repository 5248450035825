import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

const Container = styled.button`
  width: 120px;
  border: none;
  border-radius: 10px;
  padding: ${$.layout().padding5}px ${$.layout().padding4}px;
  color: ${$.color.black2};
  opacity: 1;
  transition: all 0.25s ${$.easingFn.standard};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      opacity: 0.65
      cursor: pointer;
    }
  }
  // #endregion
`;

const Button = ({ children, onClick, className }) => (
  <Container className={className} onClick={onClick}>
    {children}
  </Container>
);

Button.defaultProps = {
  children: <></>,
  onClick: () => {},
  className: '',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Button;
