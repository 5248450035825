const getPermissionGroupText = (group) => {
  switch (group) {
    case 'admin':
      return 'Administrator';
    case 'hr':
      return 'Human Resource';
    case 'client':
      return 'Employer';
    default:
      return 'Anonymous';
  }
};

export default {};

export { getPermissionGroupText };
