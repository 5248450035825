const initialState = {
  name: '',
  email: '',
  groups: [],
  editingCompany: [],
};

export default (state = initialState, { payload, type } = {}) => {
  const newState = { ...state };

  switch (type) {
    case 'GET_USER':
      newState.name = payload.name || '';
      newState.email = payload.email || '';
      newState.editingCompany = payload.editingCompany || '';
      newState.groups = payload.groups || '';

      return newState;
    case 'CLEAR_USER':
      return {
        name: '',
        email: '',
        groups: [],
        editingCompany: [],
      };
    default:
      return newState;
  }
};
