import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import AddIcon from '../../../assets/icons/add.svg';
import SGCCLogo from '../../../assets/images/sgcc-logo.png';
import Profile from './Profile';

const Container = styled.nav`
  border: 1px solid ${$.color.lightgray1};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding4}px calc((100vw - 1200px) / 2);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: ${$.layout().padding4}px ${$.layout().padding3}px;
  }
  // #endregion
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > span {
    font-size: 18px;
    color: ${$.color.black2};
    br {
      display: none;
    }
  }

  & > * {
    &:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    & > span {
      font-size: 15px;
      br {
        display: block;
      }
    }
  }
  // #endregion
`;

const LogoImg = styled(Link)`
  width: 35px;
  height: 35px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }
  }
`;

const NewJobButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${$.color.green};
  border-radius: 15px;
  padding: 5px 15px 5px 10px;
  opacity: 1;
  transition: opacity 0.25s ${$.easingFn.standard};
  text-decoration: none;

  &:visited {
    color: ${$.color.white};
  }

  & > span {
    text-transform: uppercase;
    font-family: SF Pro Bold;
    color: ${$.color.white};
    font-size: 15px;
    line-height: 18px;
  }

  & > svg {
    width: 21px;
    height: 21px;
    fill: ${$.color.white};
    margin-right: 3px;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    padding: 5px 10px;

    & > span {
      display: none;
    }

    & > svg {
      margin-right: 0;
    }
  }
  // #endregion
`;

const Navbar = () => {
  const data = useSelector(({ userData }) => userData);

  return (
    <Container>
      <Title>
        <LogoImg to="/">
          <img src={SGCCLogo} title="Home" alt="SGCC Logo" />
        </LogoImg>
        <span>Jobs Database</span>
      </Title>
      <Buttons>
        {data.groups.indexOf('hr') > -1 ? (
          ''
        ) : (
          <NewJobButton title="Post a job" to="/new/">
            <AddIcon />
            <span>Post a job</span>
          </NewJobButton>
        )}
        <Profile />
      </Buttons>
    </Container>
  );
};

export default Navbar;
