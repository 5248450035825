import React, { useState } from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';
import ProfileIcon from '../../../assets/icons/profile.svg';
import Dropdown from './Dropdown';
import SideBar from './SideBar';
import Utils from '../../../utils';

const StyledDropdown = styled(Dropdown)``;

const Container = styled.div`
  position: relative;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      ${StyledDropdown} {
        transform: translateY(0);
        pointer-events: initial;
        opacity: 1;
        z-index: 1;
      }
    }
  }
  // #endregion
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & svg {
    width: 30px;
    height: 30px;
    fill: ${$.color.black2};
    transition: fill 0.25s ${$.easingFn.standard};
  }
`;

const Profile = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <Container>
      <Icon
        onClick={() => {
          if (Utils.isMobile() || Utils.isTablet()) {
            setShowSideBar((prev) => !prev);
          }
        }}
      >
        <ProfileIcon />
      </Icon>
      <StyledDropdown />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
    </Container>
  );
};

export default Profile;
