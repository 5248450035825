import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/client';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import { clearUser } from '../../../actions';
import { getPermissionGroupText } from './helper';
import CloseIcon from '../../../assets/icons/delete.svg';

const Background = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.35s ${$.easingFn.standard};
  background-color: rgba(44, 49, 59, 0.67);
  top: 0;
  left: 0;

  &.show {
    pointer-events: initial;
    opacity: 1;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion
`;

const Container = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 2;
  width: calc(90vw - ${$.layout().padding3 * 2}px);
  right: 0;
  transform: translateX(100%);
  height: calc(100vh - ${$.layout().padding3 * 2}px);
  opacity: 0;
  overflow: auto;
  background-color: ${$.color.white};
  transition: transform 0.4s ${$.easingFn.standard},
    opacity 0.5s ${$.easingFn.standard};
  top: 0;
  padding: ${$.layout().padding3}px;
  display: flex;
  flex-direction: column;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    display: none;
  }
  // #endregion

  &.show {
    transform: translateX(0);
    pointer-events: initial;
    opacity: 1;
  }
`;

const InnerContainer = styled.div`
  margin-bottom: ${$.layout().margin4}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  font-size: 17px;
  font-family: SF Pro Bold;
  word-break: break-word;
  margin-bottom: ${$.layout().margin5}px;
`;

const Email = styled.div`
  font-size: 15px;
  color: ${$.color.orange};
  word-break: break-word;
  margin-bottom: ${$.layout().margin5}px;
`;

const Permission = styled.div`
  font-size: 14px;
  color: ${$.color.orange};
  word-break: break-word;
  text-transform: uppercase;
`;

const CloseButton = styled.div`
  align-self: flex-end;
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${$.color.gray3};
  margin: 20px 0;
`;

const NavLinks = styled.div`
  height: 70%;
  width: 100%;
  overflow: scroll;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const NavLink = styled.div`
  width: 100%;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${$.color.blue};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
    }
  }
  // #endregion
`;

const SideBar = ({ showSideBar, setShowSideBar }) => {
  const containerRef = useRef(null);
  const data = useSelector(({ userData }) => userData);
  const client = useApolloClient();
  const [clearOneUser] = Utils.useActions([clearUser]);

  Utils.useOutsideClick([containerRef], () => {
    if (containerRef.current.className.indexOf('show') > -1) {
      setShowSideBar(false);
    }
  });

  return (
    <>
      <Background className={showSideBar ? 'show' : ''} />
      <Container ref={containerRef} className={showSideBar ? 'show' : ''}>
        <InnerContainer>
          <CloseButton
            onClick={() => {
              setShowSideBar(false);
            }}
          >
            <CloseIcon />
          </CloseButton>
          <Name>{data.name}</Name>
          <Email>{data.email}</Email>
          <Permission>{getPermissionGroupText(data.groups[0])}</Permission>
          <Divider />
          <NavLinks>
            <NavLink
              onClick={() => {
                Auth.signOut()
                  .then(() => {
                    clearOneUser();
                    client.stop();
                    client.clearStore();
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            >
              Logout
            </NavLink>
          </NavLinks>
        </InnerContainer>
      </Container>
    </>
  );
};

SideBar.defaultProps = {
  showSideBar: false,
  setShowSideBar: () => {},
};

SideBar.propTypes = {
  showSideBar: PropTypes.bool,
  setShowSideBar: PropTypes.func,
};

export default SideBar;
