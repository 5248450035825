import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import { clearUser } from '../../../actions';
import Utils from '../../../utils';
import { getPermissionGroupText } from './helper';
import $ from '../../../styles/global';

const Container = styled.div`
  width: 220px;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  transform: translateY(-0.8em);
  position: absolute;
  transition: transform 0.35s ${$.easingFn.standard},
    opacity 0.35s ${$.easingFn.standard};
  top: 20px;
  right: 0;
  display: block;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      pointer-events: initial;
      opacity: 1;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    display: none;
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    display: none;
  }
  // #endregion
`;

const HiddenPadding = styled.div`
  height: 20px;
  background-color: transparent;
  width: 100%;
`;

const InnerContainer = styled.div`
  width: calc(100% - ${$.layout().padding4 * 2}px);
  background-color: ${$.color.white};
  border: 1px solid ${$.color.gray1};
  border-radius: 10px;
  height: calc(100% - 22px);
  box-shadow: ${$.dropShadow.normal};
  padding: ${$.layout().padding4}px;
`;

const Name = styled.div`
  font-size: 16px;
  font-family: SF Pro Bold;
  word-break: break-word;
  margin-bottom: 5px;
`;

const Email = styled.div`
  font-size: 14px;
  color: ${$.color.orange};
  word-break: break-word;
  margin-bottom: ${$.layout().margin5}px;
`;

const Permission = styled.div`
  color: ${$.color.orange};
  font-size: 13px;
  word-break: break-word;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${$.color.gray2};
  margin: 15px 0;
`;

const NavLinks = styled.div`
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const NavLink = styled.div`
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${$.color.blue};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
    }
  }
  // #endregion
`;

const Dropdown = ({ className }) => {
  const client = useApolloClient();
  const data = useSelector(({ userData }) => userData);
  const permission = getPermissionGroupText(data.groups[0]);
  const [clearOneUser] = Utils.useActions([clearUser]);

  return (
    <Container className={className}>
      <HiddenPadding />
      <InnerContainer>
        <Name title={`Account Name: ${data.name}`}>{data.name}</Name>
        <Email title={`Account Email: ${data.email}`}>{data.email}</Email>
        <Permission title={`Account Permission: ${permission}`}>
          {permission}
        </Permission>
        <Divider />
        <NavLinks>
          <NavLink
            title="Logout"
            onClick={() => {
              Auth.signOut()
                .then(() => {
                  clearOneUser();
                  client.stop();
                  client.clearStore();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            Logout
          </NavLink>
        </NavLinks>
      </InnerContainer>
    </Container>
  );
};

Dropdown.defaultProps = {
  className: '',
};

Dropdown.propTypes = {
  className: PropTypes.string,
};

export default Dropdown;
