import React, { useEffect } from 'react';
import shortid from 'shortid';
import { Link } from 'gatsby';

import $ from '../styles/global';
import useActions from './useActions';
import choices from '../components/Listing/items.json';

/**
 * Retrieves 1 absolute path to the Webpack processed image from the assets/image folder.
 * @param {string} pathToImage
 * @return {string}
 */
const importOneImage = (pathToImage) =>
  require.context('../assets/images', true)(`./${pathToImage}`);

/**
 * Retrieves 1 SVG image as React Component from assets/image.
 * @param {string} pathToSVG
 * @param {object} props - Extra attribute you wish to add to the SVG component.
 * @returns {React.Component}
 */
const importOneSVG = (pathToSVG, props = {}) => {
  const svgImage = importOneImage(pathToSVG);
  const { defaultProps } = svgImage;

  return svgImage({ ...props, ...defaultProps });
};

/**
 * Returns true if screen is mobile size. False if otherwise.
 * @returns {bool}
 */
const isMobile = () => window.matchMedia($.device.mobile).matches;

/**
 * Returns true if screen is tablet size. False if otherwise.
 * @returns {bool}
 */
const isTablet = () => window.matchMedia($.device.tablet).matches;

/**
 * Returns true if screen is desktop size. False if otherwise.
 * @returns {bool}
 */
const isDesktop = () => window.matchMedia($.device.desktop).matches;

/**
 * Informs if a click outside the given ref is done.
 * @param {array} refArray - Array of component refs to avoid
 * @param {func} callback - A callback function to run if user clicked outside the array of
 *  components
 */
const useOutsideClick = (refArray, callback) => {
  const handleOutsideClick = (event) => {
    let outsideClick = false;

    if (!(Array.isArray(refArray) && refArray.length > 0)) {
      console.log('Please pass in an array of refs.');
    }

    refArray.forEach((ref) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (refArray.length > 1) {
          refArray
            .filter((refPrime) => {
              if (ref === refPrime) {
                return false;
              }
              return true;
            })
            .forEach((refPrime) => {
              if (!refPrime.current.contains(event.target)) {
                outsideClick = true;
              }
            });
        } else {
          outsideClick = true;
        }
      }
    });

    if (outsideClick) {
      callback(event);
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, []);
};

/**
 * Takes in 1 array of objects, and add keys to them.
 * @param {array} items - 1 array of objects.
 * @returns {array}
 */
const addKeys = (items) =>
  items.map((item) => ({ ...item, key: shortid.generate() }));

/**
 * Takes in a string, and convert internal link strings to actual Gatsby link components
 * Returns an array of the string with the link components spliced in it.
 * You can directly use the results of this util function into a JSX component.
 * @param {string} subject - String with <Link><linkURL>|<linkText></Link> in it.
 * Even if there aren't any matching substrings in it, it will just return an array of one item
 * You can still use it directly as a children of a component.
 * @param {React.Component} [ LinkComponent ] - Optional parameter to use your own
 * Link component instead. If not passed in, it will use the default styled Link component here
 * @returns {array} - returns an array of spliced in original string and Gatsby Link components
 */
const parseStringToGatsbyLink = (subject, LinkComponent = Link) =>
  subject.split(/<Link>|<\/Link>/).map((ele) => {
    if (/\/.+?\|.+/.test(ele)) {
      const key = shortid.generate();
      return (
        <LinkComponent key={key} to={ele.split('|')[0]}>
          {ele.split('|')[1]}
        </LinkComponent>
      );
    }
    return ele;
  });

const parseTimeToDayMonth = (item) => {
  const date = new Date(item);
  const day = date.getDate();
  const month = date.toLocaleString('en-us', { month: 'short' });

  return `${day} ${month}`;
};

const parseTimeToDayName = (item) => {
  const date = new Date(item);

  return date.toLocaleString('en-us', { weekday: 'short' });
};

/**
 * Return URL search param value based on given name
 * @param {string} name - Name of the param
 * @returns {string}
 */
const getSearchParam = (name) => {
  let searchParams;

  if (typeof window !== 'undefined') {
    searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(name) || '';
  }

  return '';
};

/**
 * Converts timestamp to string. Parses all necessary wording for the
 * timestamp here. Includes "Created at...", "Updated at..." and the earliest
 * date in returned object. Pick the tagline you need!
 *
 * 1. Shows DD MMM YYYY.
 * 2. Hides current year but shows previous years.
 * 3. If the job is newly created, lastCreated key will have the value
 * `Created at`.Otherwise, it will have the value `Updated at`.
 *
 * @param {string} createdAt
 * @param {string} updatedAt
 * @returns {object}
 */
const translateJobTimestampToString = ({ createdAt, updatedAt }) => {
  const getDate = (ts) => {
    const d = new Date(ts);
    const fullString = d.toLocaleDateString('en-GB', {
      timeZone: 'Asia/Hong_Kong',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const currentYear = d.getFullYear();

    return fullString.replace(` ${currentYear}`, '');
  };
  const getTime = (ts) => {
    const d = new Date(ts);

    return d.toLocaleTimeString('en-US', {
      timeZone: 'Asia/Hong_Kong',
      hour: '2-digit',
      minute: '2-digit',
    });
  };
  const createdAtString = `Created at ${getDate(createdAt)}, ${getTime(
    createdAt
  )}`;
  const updatedAtString = `Updated at ${getDate(updatedAt)}, ${getTime(
    updatedAt
  )}`;

  return {
    createdAt: createdAtString,
    updatedAt: updatedAtString,
    lastCreated: createdAt === updatedAt ? createdAtString : updatedAtString,
  };
};

/**
 * Returns the text attached to the related enumeration.
 * The list of items are from `components/Listing/items.json`
 *
 * E.g. MORETHANTENYEARS will return the text "More than 10 years".
 *
 * @param {string} type - Name of the field collection. Refer to file.
 * @param {string} value - Enum name
 * @returns {string}
 */
const getEnumText = ({ type, value }) => {
  const obj = choices.filter(({ type: objType }) => objType === type)[0];

  return obj.choices.filter(({ value: objValue }) => value === objValue)[0]
    .text;
};

export default {
  isMobile,
  isDesktop,
  isTablet,
  getSearchParam,
  translateJobTimestampToString,
  getEnumText,
  importOneImage,
  importOneSVG,
  useOutsideClick,
  addKeys,
  parseStringToGatsbyLink,
  parseTimeToDayMonth,
  parseTimeToDayName,
  useActions,
};
