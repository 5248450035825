import { combineReducers } from 'redux';

import filterData from './filterReducer';
import jobData from './jobReducer';
import modalData from './modalReducer';
import userData from './userReducer';

const rootReducer = combineReducers({
  filterData,
  jobData,
  modalData,
  userData,
});

export default (state, action) =>
  rootReducer(action.type === 'CLEAR_USER' ? undefined : state, action);
