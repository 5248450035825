import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import { navigate } from 'gatsby';
import {
  Authenticator,
  SignIn,
  ForgotPassword,
  ConfirmSignIn,
  RequireNewPassword,
} from 'aws-amplify-react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { getUser } from '../../actions';
import Utils from '../../utils';
import Navbar from './Navbar';
import Footer from './Footer';
import Connect from './Connect';
import Modal from './Modal';
import awsConfig from '../../../aws-config';
import $ from '../../styles/global';

Amplify.configure(awsConfig);

const amplifyTheme = {
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formSection: {
    borderRadius: '10px',
    border: `1px solid ${$.color.gray1}`,
    padding: '30px',
    minWidth: '300px',
    maxWidth: '500px',
    width: '30vw',
    backgroundColor: $.color.white,
  },
  sectionHeader: {
    fontSize: '16px',
    fontFamily: 'SF Pro Regular',
    color: $.color.gray2,
    textTransform: 'uppercase',
    marginBottom: `${$.layout().margin3}px`,
  },
  input: {
    outline: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: `calc(100% - 20px)`,
    fontFamily: 'SF Pro Regular',
    fontSize: '15px',
    color: $.color.black2,
    lineHeight: '16px',
    backgroundColor: $.color.lightgray3,
    border: 'none',
    marginBottom: `${$.layout().margin3}px`,
  },
  inputLabel: {
    fontFamily: 'SF Pro Bold',
    fontSize: '15px',
    marginBottom: `${$.layout().margin5}px`,
    color: $.color.black2,
    textTransform: 'uppercase',
  },
  hint: {
    fontSize: '14px',
    color: $.color.black2,
    fontFamily: 'SF Pro Regular',
    marginBottom: `${$.layout().margin4}px`,
  },
  a: {
    fontFamily: 'SF Pro Bold',
    color: $.color.blue,
    fontSize: '13px',
  },
  sectionFooter: {
    fontSize: '16px',
  },
  button: {
    fontSize: '16px',
    fontFamily: 'SF Pro Regular',
    borderRadius: '10px',
    border: 'none',
    color: $.color.white,
    backgroundColor: $.color.black2,
    width: '100%',
    padding: `10px 0`,
    outline: 'none',
  },
  toast: {
    backgroundColor: $.color.gray1,
    color: $.color.black2,
    padding: `${$.layout().padding4}px 0`,
    textAlign: 'center',
    fontFamily: 'SF Pro Regular',
    fontSize: '16px',
  },
  sectionFooterSecondaryContent: {
    display: 'block',
    marginTop: `${$.layout().margin4}px`,
  },
};

const Title = styled.h2`
  ${({ show }) => `
  display: ${show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SF Pro Bold;
  color: ${$.color.black2};
  font-size: 40px;
  margin: ${$.layout().margin2}px;

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    font-size: 35px;
    text-align: center;
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 30px;
    text-align: center;
  }
  // #endregion
`}
`;

const Container = styled.div`
  ${({ show }) => `
  display: ${show ? 'flex' : 'none'};
  flex-direction: column;
  height: 100vh;
`}
`;

const Body = styled.div`
  flex: 1 0 auto;
  background-color: #fafaff;

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    > section {
      width: 1200px;
      padding-left: calc((100vw - 1200px) / 2);
      padding-right: calc((100vw - 1200px) / 2);
    }
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    > section {
      width: calc(100vw - ${$.layout().padding3 * 2}px);
      padding-left: ${$.layout().padding3}px;
      padding-right: ${$.layout().padding3}px;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    > section {
      width: calc(100vw - ${$.layout().padding3 * 2}px);
      padding-left: ${$.layout().padding3}px;
      padding-right: ${$.layout().padding3}px;
    }
  }
  // #endregion
`;

const LayoutComponent = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [getOneUser] = Utils.useActions([getUser]);

  return (
    <Authenticator
      hideDefault
      theme={amplifyTheme}
      onStateChange={(state) => {
        switch (state) {
          case 'signedIn':
            getOneUser();
            setIsLoggedIn(true);
            break;
          case 'signIn':
            navigate('/');
            setIsLoggedIn(false);
            break;
          default:
            setIsLoggedIn(false);
            break;
        }
      }}
    >
      <Title show={isLoggedIn === false}>Jobs Database</Title>
      <SignIn />
      <ForgotPassword />
      <ConfirmSignIn />
      <RequireNewPassword />
      <Container show={isLoggedIn}>
        <Connect isLoggedIn={isLoggedIn}>
          <Navbar />
          <Modal />
          <ToastContainer autoClose={4500} closeOnClick />
          <Body>{children}</Body>
          <Footer />
        </Connect>
      </Container>
    </Authenticator>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
