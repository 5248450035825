import $ from '../styles/global';

const initialState = {
  open: false,
  title: '',
  message: '',
  options: [
    {
      text: 'No',
      color: $.color.white,
      callback: () => {},
    },
    {
      text: 'Yes',
      color: $.color.green,
      callback: () => {},
    },
  ],
};

export default (state = initialState, { payload, type } = {}) => {
  const newState = { ...state };

  switch (type) {
    case 'OPEN_MODAL':
      return { ...newState, ...payload, open: true };
    case 'CLOSE_MODAL':
      return {
        open: false,
        title: '',
        message: '',
        options: [
          {
            text: 'No',
            color: $.color.white,
            callback: () => {},
          },
          {
            text: 'Yes',
            color: $.color.green,
            callback: () => {},
          },
        ],
      };
    default:
      return newState;
  }
};
